<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" app clipped :color="darkTheme ? 'grey darken-4' : '#E3E5E6'" class="elevation-2">
			<v-list dense>
				<template v-for="(item, idx) in appMenu">
					<v-list-item v-if="!item.Separator && !item.AdminOnly" :key="idx" :to="{ name: item.MenuId }" link>
						<v-list-item-icon><v-icon>{{ item.Icon }}</v-icon></v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.MenuName }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item v-if="!item.Separator && item.AdminOnly && currentUser.IsAdmin" :key="idx" :to="{ name: item.MenuId }" link>
						<v-list-item-icon>
							<v-icon :color="darkTheme ? 'green lighten-2': 'green darken-4'">{{ item.Icon ? item.Icon : 'mdi-cog' }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.MenuName }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider v-if="item.Separator" :key="idx+'div'" />
				</template>

				<v-divider />
				<v-list-item to="/impostazioni/0">
					<v-list-item-icon>
						<v-icon>mdi-format-list-checks</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Impostazioni</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				
				<v-list-item to="/logout">
					<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Esci</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider />
			</v-list>

			<v-footer color="transparent" padless>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" class="grey--text">
							<h6>
								UI v{{ uiVersion }} <v-chip v-if="uiVersionBetaTag" x-small outlined label>
									{{ uiVersionBetaTag }}
								</v-chip>  <v-chip v-if="appSetup.isTest" x-small color="primary" outlined label>
									TEST
								</v-chip> <v-chip v-if="appSetup.isDevelop" x-small color="warning" outlined label>
									DEV
								</v-chip>
							</h6>
							<h6>API v{{ apiVersion }} - {{ apiDate }}</h6>
						</v-col>
					</v-row>
				</v-container>
			</v-footer>
		</v-navigation-drawer>

		<v-app-bar app clipped-left :color="appSetup.appColor" dense dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" />
			<v-toolbar-title class="mr-12 align-center">
				<span class="title">{{ appSetup.appName }}</span>
				<div v-if="viewDebugElements" class="d-inline-block align-start ml-5">
					<v-chip small class="d-flex d-sm-none" color="light-blue darken-2">
						XS
					</v-chip>
					<v-chip small class="d-none d-sm-flex d-md-none" color="teal darken-2">
						SM
					</v-chip>
					<v-chip small class="d-none d-md-flex d-lg-none" color="green darken-2">
						MD
					</v-chip>
					<v-chip small class="d-none d-lg-flex d-xl-none" color="lime darken-2">
						LG
					</v-chip>
					<v-chip small class="d-none d-xl-flex" color="yellow darken-2">
						XL
					</v-chip>
				</div>
			</v-toolbar-title>
			<div class="flex-grow-0" />
			<v-spacer />
			<div class="d-flex">
				<v-gravatar class="img-circle mr-3 pa-1" :email="currentUser.Email" />
				<div class="d-flex flex-column mr-3">
					<div class="text-no-wrap body-1">
						{{ currentUser.Nome }} {{ currentUser.Cognome }}
					</div>
					<div class="caption">
						{{ currentUser.Email }}
					</div>
				</div>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn dark icon v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item @click="toggleTheme">
							<v-list-item-title>
								<v-icon v-if="darkTheme" left>
									mdi-lightbulb-on-outline
								</v-icon>
								<v-icon v-else left>
									mdi-lightbulb-on
								</v-icon>								
								Tema {{ (darkTheme ? 'chiaro': 'scuro') }}
							</v-list-item-title>
						</v-list-item>
						<v-list-item to="/impostazioni/0">
							<v-list-item-title>
								<v-icon left>
									mdi-format-list-checks
								</v-icon>Impostazioni
							</v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item to="/impostazioni/1">
							<v-list-item-title>
								<v-icon left>
									mdi-shield-lock-outline
								</v-icon>Cambia password
							</v-list-item-title>
						</v-list-item>
						<v-list-item to="/impostazioni/2">
							<v-list-item-title>
								<v-icon left>
									mdi-account-box-outline
								</v-icon>Dati personali
							</v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item to="/logout">
							<v-list-item-title>
								<v-icon left>
									mdi-logout
								</v-icon>Esci
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</v-app-bar>

		<!-- <v-main :class="isAdminPage ? (darkTheme ? 'brown darken-4' : 'orange lighten-5') : ''"> -->
		<v-main :style="pageBackground">
			<!-- necessario per mantenere invariato lo stato della lista persone durante il drilldown su persona -->
			<keep-alive include="persone-page">
				<router-view />
			</keep-alive> 
		</v-main>
	</v-app>
</template>

<script>
export default {
	data: () => ({
		drawer: null,
		isCreated: false,
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		uiVersion() { return this.$store.getters['uiVersion'] },
		uiVersionBetaTag() { return this.$store.getters['uiVersionBetaTag'] },
		apiVersion() { return this.$store.getters['apiVersion'] },
		apiDate() { return this.$store.getters['apiDate'] },
		darkTheme() { return this.$vuetify.theme.dark },
		keyvalues() { return this.$store.getters['keyvalues'] },
		isLoggedIn() { return this.$store.getters['isLoggedIn'] },
		appMenu() { return this.$store.getters['appMenu'] },
		currentUser() { return this.$store.getters['currentUser'] },
		isAdminPage() { return this.$store.state.route.path.startsWith('/admin') },
		pageBackground() {
			//return this.darkTheme ? 'black' : 'background-color:#F1F2F2'
			if( this.$store.state.route.path == '/home') {
				return 'background:url("./img/bck_skoda_4.png"); background-size: cover;' 
			} else {
				if(this.darkTheme) 
					return 'background:linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0)), url("./img/bck_skoda_4.png"); background-size: cover; ' 			
				else
					return 'background:linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0)), url("./img/bck_skoda_4.png"); background-size: cover; ' 
			}
			/*
			if( this.$store.state.route.path == '/home') {
				return 'background:url("./img/bck_skoda_1.jpg"); background-size: cover;' 
			} else {
				if(this.darkTheme) 
					return 'background:url("./img/bck_skoda_3.jpg"); background-size: cover;' 			
				else
					return 'background:url("./img/bck_skoda_2.jpg"); background-size: cover;' 	
			}
			*/		
		}
	},
	mounted() {
		if(this.$store.getters['keyvalues']){ 
			if(this.$store.getters['keyvalues']['theme.dark']) {
				var val1 = this.$store.getters['keyvalues']['theme.dark'] === 'true'
				this.$vuetify.theme.dark = val1 
			}
			if(this.$store.getters['keyvalues']['debugmode.on']) {
				var val2 = this.$store.getters['keyvalues']['debugmode.on'] === 'true'
				if(this.viewDebugElements !== val2) this.$store.dispatch('toggleDebugElements')
			}
		}
		// isCreated viene impostata pochi millisecondi dopo l'avvio del componente e serve ad evitare che i watcher salvino inutilmente le chiavi al primo avvio (quando sarebbero identiche a quelle appena caricate)
		// setTimeout(() => { this.isCreated=true }, 100)
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.$store.dispatch('toggleTheme', this.$vuetify.theme.dark)
		},
	}
}
</script>

<style scoped lang="less">

.img-circle {
	height: 45px;
	width: 45px;
}

</style>